/* .cardSignup {
    top: 20%;
  transform: translateY(10%);
} */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

.signupTxtField {
	padding-bottom: 3% !important;
}

@media screen and (max-width: 900px) {
	.signupTxtField {
		padding: 2% !important;
	}
}
