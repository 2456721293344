.img-quote-bottom {
	width: 100%;
	left: 0px;
	bottom: -20px;
	position: absolute;
}
.img-quote-bottom-mobile {
	width: 100%;
	left: 0px;
	bottom: -10px;
	position: absolute;
}
