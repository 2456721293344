.questionnarie-chat-container {
	position: fixed;
	bottom: 0px;
	right: 0px;
	background: linear-gradient(
		93.72deg,
		#C6EFF5 6.84%,
		#93b1ff 51.63%,
		#0056d8 107.54%
	);
	padding: 10px;
	border-radius: 30px;
	width: 330px;
	height: calc(40vh + 65px);
	overflow: hidden;
	z-index: 1200;
}

@media screen and (max-width: 1520px) {
	.questionnarie-chat-container {
		max-width: 360px;
		width: 100%;
	}
}

.questionnarie-chat::-webkit-scrollbar-thumb {
	padding: 12px 0px;
}

.questionnarie-chat-container .questionnarie-chat {
	padding: 16px;
	background: #fff;
	border-radius: 25px;
	height: 100%;
	overflow: auto;
}
.questionnarie-chat-container .questionnarie-chat::-webkit-scrollbar {
	appearance: none;
	-moz-appearance: none;
	width: 7px;
}

.questionnarie-chat-container .questionnarie-chat::-webkit-scrollbar-thumb {
	appearance: none;
	-moz-appearance: none;
	background: rgb(222, 222, 222);
	border-radius: 10px;
}
.questionnarie-chat-container .questionnarie-chat::-webkit-scrollbar-track {
	appearance: none;
	-moz-appearance: none;
	background: transparent;
}

.questionnarie-chat-container .info-chat {
	font-size: 12px;
}
.questionnarie-chat-container .developed-info {
	width: calc(100% - 65px);
	left: 32.5px;
	bottom: 10px;
	padding: 0px 30px;
	text-align: end;
	border-radius: 50px;

	position: absolute;
	font-family: "Ulagadi Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 28px;
	color: #c0c0c0;
	background-color: #fff;
}

.form-questionnarie {
	height: calc(40vh + 65px);
	padding-bottom: 50px;
}
.form-questionnarie .input-send-message {
	position: absolute;
	bottom: 12px;
	width: calc(100% - 20px);
	background: #fff;
	height: 100px;
	border-radius: 0 0 45px 45px;
	padding: 12px 20px 30px 20px;
}

.over-background {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;
	background: rgba(17, 32, 49, 0.5);
	backdrop-filter: blur(7.5px);
	transition: 0.5s;
	z-index: 10;
}
