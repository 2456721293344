.payment-background {
	display: flex;
	width: 100%;
	height: 100%;
	margin: auto 0;
	justify-content: center;
	background-color: whitesmoke;
}
.payment-container {
	display: grid;
	place-self: center;
	width: 75%;
	height: 100%;
	align-items: center;
	justify-content: stretch;
}
.confirm-pay-btn {
	margin-top: 2rem;
	display: grid;
	justify-content: center;
}
.button-text {
	font-size: 1.8rem;
}
.payment-text {
	font-size: 5rem;
	margin-bottom: 1rem;
}
