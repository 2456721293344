.questionnarie-chat-container .questions-container {
	width: 100%;
	margin-top: 10px;
	margin-bottom: 10px;
}
.questionnarie-chat-container .questions-container.ghost {
	margin-top: 10px;
	margin-bottom: 10px;
}
.questionnarie-chat-container .questions-container.ghost .awnser-ghost {
	font-size: 12px;
	padding: 7px;
	background: #0581f9;
	border-radius: 10px 10px 0px 10px;
	margin-top: 20px;
	color: #fff;
	width: 90%;
	margin-left: auto;
}
.questionnarie-chat-container .questions-container.fadeIn {
	animation-name: fadeIn;
	animation-duration: 0.5s;
}
.questionnarie-chat-container .questions-container.fadeOut {
	opacity: 0;
	/* animation-name: fadeOut;
    animation-duration: 0.5s;
    animation-fill-mode: forwards; */
}
.questionnarie-chat-container .message-container-left {
	display: flex;
	align-items: flex-end;
}
.questionnarie-chat-container .message-container-left img {
	background: #3a7ae7;
	border-radius: 100%;
	padding: 5px;
}
.questionnarie-chat-container .message-container-right {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}
.questionnarie-chat-container .message-container-right img {
	border-radius: 100%;
	width: 30px;
	height: 30px;
	padding: 0px;
}
.questionnarie-chat-container .message-container-right .css-1irlvzw {
	width: -webkit-fill-available;
}
.form-questionnarie .loading-box {
	height: fit-content;
	padding-bottom: 50px;
}

.options-box {
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
	margin-left: 12px;
	margin-top: 20px;
	padding-bottom: 50px;
	justify-content: flex-end;
}
.questionnarie-chat-container .input-question {
	padding: 10px 15px;
	width: 85%;
	border-radius: 10px;
	border: none;
	background: #ddd;
	margin-top: 0px;
}
.questionnarie-chat-container .pregunta {
	padding: 10px;
	border-radius: 10px 10px 10px 0px;
	background: #676767;
	color: #fff;
	font-size: 13px;
	width: 90%;
}
.options-box .btn-opcion {
	background: #ddd;
}
.options-box .radio {
	display: none;
}
.options-box .label-radio {
	padding: 7px 30px;
	border-radius: 10px;
	font-size: 12px;
	transition: 0.5s;
	margin-right: 0px;
	border: none;
	box-shadow: -1px 0px 5px 0px rgba(54, 54, 54, 0.75);
	-webkit-box-shadow: -1px 0px 5px 0px rgba(54, 54, 54, 0.75);
	-moz-box-shadow: -1px 0px 5px 0px rgba(54, 54, 54, 0.75);
	cursor: pointer;

	background: #C6EFF5;
	border-radius: 45px;
	box-shadow: none;
	font-family: "Ulagadi Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	text-align: center;
	color: #fff;
	min-width: 100px;
}
.options-box .radio[type="radio"]:checked + .label-radio {
	background: linear-gradient(
		93.72deg,
		#C6EFF5 6.84%,
		#93b1ff 51.63%,
		#0056d8 107.54%
	);
	color: #fff;
	transition: 0.5s;
}
.options-box .label-radio.active {
	/* background: linear-gradient(93.72deg, #C6EFF5 6.84%, #93B1FF 51.63%, #0056D8 107.54%); */
	background: #C6EFF5;
	color: #fff;
	transition: 0.5s;
}
.questionnarie-chat-container .btn-prev {
	background: #646464;
	color: #fff;
}
.questionnarie-chat-container .btn-next {
	text-decoration: none;
	background: rgb(46, 129, 253);
	color: #fff;
	width: 13%;
	padding: 0px !important;
	margin: 0px;
	height: calc(1em + 20px);
	display: flex;
	justify-content: center;
	align-items: center;
}
.questionnarie-chat-container .btn {
	border: none;
	padding: 10px 40px;
	border-radius: 10px;
	cursor: pointer;
	transition: 0.5s;
}
.questionnarie-chat-container .btn:disabled {
	background-color: #ddd;
	color: #646464;
	cursor: no-drop;
	transition: 0.5s;
}

.questionnarie-chat-container .input-send-message {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	margin-top: 0px;
}
.attachment-container {
  display: flex;
}

.attachment-container.empty {
  display: none;
}

@keyframes fadeIn {
	from {
		margin-left: 100px;
		opacity: 0;
	}
	to {
		margin-left: 0px;
		opacity: 1;
	}
}

@keyframes fadeOut {
	from {
		transform: scale(1);
	}
	to {
		transform: scale(0);
	}
}

@media screen and (max-width: 900px) {
	.questionnarie-chat-container {
		padding: 10px;
	}
	.input-question {
		width: 80% !important;
	}
	.input-send-message .btn-next {
		margin-top: 0px !important;
		width: 17%;
	}

	.form-questionnarie p {
		font-size: 14px;
	}
}
