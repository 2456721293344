.not-found-text {
	font-size: 15rem;
        align-self: flex-end;
}
.not-found-container {
	display: grid;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
}
