.my-profile-container .inputs-container {
	/* padding: 65px 0px;
    width: 35%;
    margin-top: 20px; */
}
/* .my-profile-container input{
    padding: 9px;
    border: 1px solid;
    width: 100%;
    margin-bottom: 10px;
} */
.my-profile-container .nds-options-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.my-profile-container .nds-options-container Typography {
	margin: 0px;
}
/* .my-profile-container .nds-options-container button{
    background: #818181;
    color: #fff;
    border-radius: 10px;
    border: 1px solid #333;
    padding: 10px 30px;
    font-size: 13px;
    cursor: pointer;
} */
.my-profile-container .btn {
	background: rgb(46, 129, 253);
	color: #fff;
	border-radius: 10px;
	border: none;
	padding: 10px 30px;
	font-size: 13px;
	cursor: pointer;
	margin: auto;
	display: block;
}

@media screen and (max-width: 900px) {
	.my-profile-container form {
		width: 100%;
	}
	.my-profile-container input {
		width: 92%;
	}
	.my-profile-container .nds-options-container {
		font-size: 13px;
		justify-content: space-between;
	}
	.my-profile-container .btn {
		margin-top: 30px;
	}
}
