/* GENERAL */
.schedule-container {
	/* display: flex;
    flex-wrap: wrap;

    align-items: center;
    padding: 50px;
    height: 100%;
    color: #333; */
}
.schedule-container .schedule-box {
	width: 100%;
}
.schedule-container .col-100 {
	display: flex;
	gap: 20px;
}
.schedule-container .col-50 {
	width: 50%;
	display: flex;
	flex-wrap: wrap;
}
.schedule-container .col-40 {
	width: 40%;
	display: flex;
	flex-wrap: wrap;
}
.schedule-container .col-60 {
	width: 60%;
	display: flex;
	flex-wrap: wrap;
}
.google-calendar-box {
	display: flex;
	align-items: center;
	margin: 20px 0px;
}
.google-calendar-box img {
	width: 60px;
	height: 60px;
}
.google-calendar-box .col-70 {
	margin-left: 20px;
	height: min-content;
}
.google-calendar-box p {
	margin: 0px;
	width: 100%;
}

/* CALENDAR */

.schedule-container .calendar-container {
	width: 100%;
	padding: 30px;
	background: #fff;
	border-radius: 20px;
}
.schedule-container .calendar-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 5px;
}
.schedule-container .prev-next-month-btn {
	background: #ddd;
	border: none;
	height: 40px;
	width: 40px;
	border-radius: 100%;
	font-weight: 900;
	font-size: 22px;
	color: #787878;
}
.schedule-container .date-title {
	font-weight: 700;
}
.schedule-container .weeks {
	display: flex;
	list-style: none;
	gap: 10px;
}
.schedule-container .weeks li {
	width: 14.2%;
	padding: 10px;
	box-sizing: border-box;
	text-align: center;
	border-radius: 10px;
	cursor: pointer;
	transition: 0.5s;
}
.schedule-container .days {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	gap: 0px;
}
.schedule-container .days li {
	width: 14.2%;
	padding: 10px;
	box-sizing: border-box;
	text-align: center;
	border-radius: 10px;
	background: #fff;
	cursor: pointer;
	transition: 0.5s;
	border-radius: 0px;
	border: 1px solid rgb(83, 83, 83);
}
.schedule-container .days li.disable {
	background-color: #efefef;
	border: 1px solid rgb(209, 209, 209);
}
.schedule-container .days li.current {
	border: 1px solid rgb(7, 27, 202);
	color: rgb(7, 27, 202);
}
.schedule-container .days li.selected {
	background-color: rgb(25 118 210);
	border: 3px solid rgb(7, 27, 202);
	color: #fff;
}
.schedule-container .days li.selected:hover {
	background-color: rgb(14, 83, 151);
	color: #fff;
}
.schedule-container .days li:hover {
	background-color: rgb(138, 179, 218);
	transition: 0.5s;
}

/* TIME */
.schedule-container .hours-container {
	width: 100%;
	padding: 30px;
	background: #fff;
	border-radius: 20px;
	max-height: 400px;
	overflow: auto;
}
.schedule-container .hours-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.schedule-container .hours-header .date-title {
	display: block;
	width: 100%;
	text-align: center;
}
.schedule-container .hours {
	padding: 0px;
	list-style: none;
}
.schedule-container .hours li {
	padding: 1vh 10px;
	box-sizing: border-box;
	border-radius: 10px;
	background: #efefef;
	cursor: pointer;
	transition: 0.5s;
	margin-bottom: 10px;
}
.schedule-container .hours li:hover {
	background-color: #ddd;
	transition: 0.5s;
}
.schedule-container .hours li.selected-hour {
	background-color: rgb(25 118 210);
	color: #fff;
}
.schedule-container .hours li.selected-hour:hover {
	background-color: rgb(14, 83, 151);
	color: #fff;
}

.schedule-container .btn-next {
	background: #1976d2;
	color: #fff;
	border-radius: 10px;
	border: none;
	padding: 10px 40px;
	margin-top: 20px;
	font-size: 14px;
	margin-top: 10px;
	display: block;
	width: fit-content;
	text-decoration: none;
}
.google-calendar-link {
	font-family: "Ulagadi Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	text-align: right;
	text-decoration-line: underline;
	color: #0056d8;
	margin: 6px 0px 25px 0px;
}
.hour-container .tasks-container {
	display: none;
}
.hour-container:hover .tasks-container {
	display: block;
	position: absolute;
	padding: 15px 15px 15px 30px;
	border-radius: 15px;
	z-index: 100;
	background: rgba(255, 255, 255, 0.5);
	box-shadow: 8px 8px 20px rgb(192 192 192 / 25%);
	backdrop-filter: blur(20px);
	top: -20px;
	right: 0px;
	width: fit-content;
}

@media screen and (max-width: 900px) {
	/* .schedule-container {
		margin-top: 50px;
		padding: 30px;
	} */
	.schedule-container .col-100 {
		width: 100%;
		flex-wrap: wrap;
	}
	.schedule-container .col-40 {
		width: 100%;
	}
	.schedule-container .col-60 {
		width: 100%;
	}

	.schedule-container .calendar-container {
		margin-top: 30px;
		padding: 0px;
	}
	.schedule-container .prev-next-month-btn {
		height: 30px;
		width: 30px;
		font-size: 14px;
	}
	.schedule-container .weeks {
		padding: 0px;
	}
	.schedule-container .days {
		padding: 0px;
	}
	.schedule-container .weeks li {
		font-size: 12px;
		padding: 5px;
	}
	.schedule-container .days li {
		font-size: 12px;
		padding: 8px 4px;
	}

	.schedule-container .hours-container {
		padding: 10px;
	}
	.schedule-container .hours li {
		font-size: 12px;
		padding: 7px;
	}

	.css-15hdzjp-MuiPaper-root {
		max-width: 20%;
	}
	.css-wqn68g {
		width: 80% !important;
	}
}
