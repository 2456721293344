/* #root {
	font-size: 1.6rem;
} */

.MuiListItem-root .MuiTouchRipple-child {
	background-color: inherit;
}

/* .MuiDivider-root {
	font-size: 1.6rem;
} */

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
/*
html {
	font-size: 62.5%;
}
*/
body {
	min-height: 100vh;
	max-height: fit-content;
}

.general-container {
	display: grid;
	width: 100%;
	min-height: calc(100% - 69px);
	max-height: fit-content;
	margin-top: 20px;
}

/* stepper-container and step-container are unused 🗿*/
.stepper-container {
	display: flex;
	align-items: center;
	margin: 0 auto;
	width: 100%;
}
.step-container {
	display: flex;
	width: 100%;
	min-height: 100vh;
}
.router-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
}
.buttons-stepper {
	position: fixed;
	bottom: 0;
}

::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

::-webkit-scrollbar-track {
	background: linear-gradient(#264471 0%, #112031 100%);
	border-radius: 5px;
}

::-webkit-scrollbar-thumb {
	background: #C6EFF5;
	border-radius: 5px;
}

button:hover {
	cursor: pointer;
}

/* .css-qryzuz-MuiPaper-root-MuiAppBar-root {
    background-color: #264471 !important;
} */

/* @media screen and (max-width: 799px) {
	.margin-top-movile{
		height: 69px;
		width: 100%;
	}

	.css-1dw6sgr{
		flex-wrap: wrap;
	}
	.css-wqn68g{
		width: 100% !important;
	}
	.menu-mobile{
		width: 100%;
		background: #000000de;
		display: flex;
		padding: 10px 20px;
		position: fixed;
	}
	.menu-mobile .ham-icon-container{
		width: 15%;
		display: flex;
		justify-content: left;
		align-items: center;
	}
	.menu-mobile .ham-icon-container button{
		background: transparent;
		border: none;
		color: #fff;
	}
	.menu-mobile .logo-mobil-container img{
		height: 45px;
	}
	.menu-mobile-container{
		width: 80vw;
		padding: 20px;
		position: relative;
	}
	.menu-mobile-container .menu-info{
		font-size: 14px;
		margin-bottom: 10px;
	}
	.menu-mobile-container .close-menu-mobile{
		background-color: transparent;
		border: none;
		position: absolute;
		top: 10px;
		right: 10px;
	}



	#account-button{
		background-color: transparent;
	}
	.btn-my-account span{
		color: #fff;
	}
	.btn-my-account .my-account-text{
		display: none;
	}

	.css-1khksvt-MuiModal-root-MuiPopover-root-MuiMenu-root .MuiPopover-paper{
		width: 300px !important;
    	left: 20vw !important;
	}
	.menu-mobile-in{
		background: #333;
		display: flex;
		justify-content: space-between;
		padding: 10px;
		align-items: center;
	}
	.menu-mobile-in img{
		height: 45px;
	}
	.menu-mobile-in .ham-icon-container button{
		background: transparent;
		border: none;
		color: #fff;
	} 
}*/
