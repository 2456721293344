.nda-container {
	padding: 50px;
	margin-top: 69px;
}
.nda-container * {
	color: #333;
}
.nda-container h2 {
	margin: 0px;
}
.nda-container .agreement-container {
	margin-top: 20px;
	padding: 30px;
	background: #fff;
	border-radius: 2px;
	font-size: 13px;
	max-height: calc(82vh - 140px);
	min-height: calc(82vh - 140px);
	overflow-y: auto;
	border: 1px solid #d7d5d5;
}

.pSignup {
	color: #bf1650;
	font-weight: 500;
}

.nda-container .agreement-container::-webkit-scrollbar {
	width: 7px;
}
.nda-container .agreement-container::-webkit-scrollbar-thumb {
	background: #8d8d8d;
	border-radius: 10px;
}
.nda-container .agreement-container::-webkit-scrollbar-track {
	background: #ddd;
}

.nda-container .sign-box {
	background-color: #ddd;
	border-radius: 2px;
	position: relative;
	border: grey dashed 2px;
	margin-top: 10px;
}
.nda-container .btn-clear {
	background-color: #a1a1a1;
	border: none;
	border-radius: 10px;
	padding: 10px 20px;
	color: #fff;
	position: absolute;
	top: 10px;
	left: 10px;
	cursor: pointer;
}
.nda-container .btn-clear:disabled {
	background-color: rgb(243, 243, 243);
	color: rgb(184, 184, 184);
	cursor: not-allowed;
}
.nda-container .label-name {
	font-weight: 600;
}
.nda-container .input-name {
	margin-top: 15px;
	width: 100%;
	padding: 10px 20px;
	border-radius: 20px;
	border: none;
	background: #d9d9d9;
	box-sizing: border-box;
	font-family: Arial, Helvetica, sans-serif;
	margin-top: 10px;
	margin-bottom: 10px;
}
.nda-container .btn-next {
	background: #1976d2;
	color: #fff;
	border-radius: 10px;
	border: none;
	padding: 10px 40px;
	margin-top: 20px;
	font-size: 14px;
	margin-top: 10px;
	display: block;
	width: fit-content;
	text-decoration: none;
}
.nda-container .btn-next.disable {
	background: rgb(199, 199, 199);
}
.sign-canvas {
	width: 100%;
	height: 100%;
}
.nda-container .btn-sign {
	background: #333;
	color: #fff;
	border-radius: 10px;
	border: none;
	padding: 10px 40px;
	margin-top: 20px;
	cursor: pointer;
}

.nda-container .btn-sign:disabled {
	background: #ddd;
	cursor: not-allowed;
}

@media screen and (max-width: 900px) {
	.nda-container {
		padding: 30px;
	}
	.agreement-container {
		padding: 20px !important;
		font-size: 11px !important;
	}
	.sign-box {
		height: calc(300px);
	}
	/*new styles*/

	.titleAgreement {
		font-size: 2.5rem;
		margin: auto;
	}

	.documentContainer {
		overflow-y: scroll;
		padding: 2%;
		height: 30vh;
		border: 1px solid gray;
	}

	.documentContainer p {
		margin: auto;
		font-size: 18pt;
	}

	.paddingContainers {
		padding-left: 3%;
		padding-right: 3%;
	}

	.signCanvasContainer {
		width: 100%;
		height: 20rem;
		border: grey dashed 1px;
	}
}

@media screen and (max-width: 1200px) {
	.buttonNdaNext {
		padding-top: 2%;
	}
	.signedText {
		font-size: 14pt !important;
	}
}

/* .signedText {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
} */

.nextStep {
	background: #1976d2;
	color: #fff;
	border-radius: 10px;
	border: none;
	padding: 10px 40px;
	margin-top: 20px;
	font-size: 14px;
	margin-top: 10px;
	display: block;
	width: fit-content;
	text-decoration: none;
}

/* Template PDF */

.pdfContent {
	width: 100%;
}

.pdfContent p {
	font-size: 6pt !important;
	color: #bf1650 !important;
}
