@media screen and (max-width: 900px) {
	.item {
		max-width: 100%;
	}
}

.invoice-container {
	display: grid;
	grid-template-rows: 0.1fr 1fr 0.1fr;
	width: 100%;
	height: 100%;
	background-color: whitesmoke;
	margin-top: 80px;
}

.invoice-form {
	place-self: center;
	display: grid;
	align-content: center;
	justify-items: center;
	margin: 0 auto;
	height: fit-content;
	width: 80%;
}

.pInvoice {
	color: #bf1650;
}

/* p::before {
  display: inline;
  content: "⚠ ";
} */

.containerGoogleLogo {
	display: flex;
	justify-content: center;
}

.containerGoogleLogo img {
	width: 50%;
}

.divider-container {
	width: 100%;
}

.invoice-form-title {
	font-size: 4rem;
	padding-bottom: 1rem;
	text-align: center;
}

.container-already-logged {
	display: flex;
	flex-wrap: wrap;
	padding: 50px;
	justify-content: center;
	align-items: center;
}
.container-already-logged p {
	width: 100%;
	text-align: center;
}
.container-already-logged button {
	background: #1976d2;
	color: #fff;
	border-radius: 10px;
	border: none;
	padding: 10px 40px;
	margin-top: 20px;
	font-size: 14px;
	margin-top: 10px;
	display: block;
	width: fit-content;
	text-decoration: none;
}
.suggestion-item {
	padding: 10px;
	background: #00000000;
}
.suggestion-item--active {
	background: #00000000;
	padding: 10px;
}
.suggestion-item--active.last-item {
	margin-bottom: 30px;
}
.suggestion-item.first-item {
	border-radius: 10px 10px 0px 0px;
}
.suggestion-item.last-item {
	margin-bottom: 30px;
	border-radius: 0px 0px 10px 10px;
}
@media screen and (max-width: 900px) {
	/* .MuiInputLabel-formControl {
    font-size: 0.9rem !important;
  } */
}

/* Dialogs */

.agreementsContainer {
	padding: 1% 5% 1% 5%;
}

.autocomplete-relative-container {
	position: relative;
	width: 100%;
}
.autocomplete-dropdown-container {
	/* margin-top: -19px; */
	padding: 0px 0px;
	width: -webkit-fill-available;
	width: stretch;
	position: absolute;
	z-index: 1;
	box-shadow: 8px 8px 20px rgb(192 192 192 / 25%);
	top: -10px;
	border-radius: 30px;
	left: 0px;
	/* color: transparent; */
}

.autocomplete-dropdown-container .suggestion-item {
}
