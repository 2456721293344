@media screen and (max-width: 900px) {
	.item {
		max-width: 100%;
	}
}

/* .css-1yehpgi-MuiPaper-root {
	width: 40% !important;
}

@media (max-width: 1199.95px){
	.css-1yehpgi-MuiPaper-root {
		width: 60% !important;
		margin-top: 40px !important;
		margin-right: auto !important;
		margin-left: auto !important;
		display: block !important;
	}

} */

/* .css-1hhf9tz-MuiButtonBase-root-MuiButton-root{
	width: 100%;
} */

.login-container {
	display: grid;
	grid-template-rows: 0.1fr 1fr 0.1fr;
	width: 100%;
	min-height: 100%;
	max-height: fit-content;
	background-color: whitesmoke;
}

.btn-container {
	width: 75%;
	margin: 0 auto;
	display: flex;
	align-items: center;
}

.login-form {
	place-self: center;
	display: grid;
	align-content: center;
	justify-items: center;
	margin: 0 auto;
	height: fit-content;
	width: 100%;
}
.divider-container {
	width: 100%;
}

.login-form-title {
	font-size: 4rem;
	padding-bottom: 1rem;
	text-align: center;
}
.custom-paper-style {
	width: 80%; /* Puedes ajustar el valor según tus necesidades */
	margin: auto; /* Centrar horizontalmente el MuiPaper */
  }