.review-container h2 {
	font-family: "Ulagadi Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 30px;
	line-height: 41px;
	/* identical to box height */

	color: #112031;
}
.review-container .subtitle-survey {
	font-family: "Ulagadi Sans";
	font-style: normal;
	font-weight: 200;
	font-size: 20px;
	color: #285d69;
}
.review-container th {
	font-family: "Ulagadi Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 19px;
	text-align: center;
}
.review-container .border td {
	border-bottom: 1px solid #eee;
}
.review-container td {
	padding: 20px 0px;
}
.review-container input {
	display: block;
	margin: auto;
}
.review-container .question {
	width: 25%;
	font-size: 14px;
	font-weight: 500;
	font-family: "Ulagadi Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;

	color: #112031;
}
.review-container .btn {
	border-radius: 10px;
	border: none;
	padding: 10px 40px;
	margin-top: 20px;
	font-size: 14px;
	display: block;
	width: fit-content;
	text-decoration: none;

	width: 300px;
	display: block;
	margin: auto;
	margin-top: 10px;
}

.review-container .evaluation-pc {
	margin-top: 40px;
}
.review-container .btn-box {
	background: rgba(255, 255, 255, 0.7);
	box-shadow: 8px 8px 30px rgba(132, 132, 132, 0.25);
	backdrop-filter: blur(20px);
	/* Note: backdrop-filter has minimal browser support */

	background: rgba(255, 255, 255, 0.7);
	box-shadow: 8px 8px 30px rgba(132, 132, 132, 0.25);
	backdrop-filter: blur(20px);
}

/* Base for label styling */
.review-container [type="radio"]:not(:checked),
.review-container [type="radio"]:checked {
	position: absolute;
	left: 0;
	opacity: 0.01;
}
.review-container [type="radio"]:not(:checked) + label,
.review-container [type="radio"]:checked + label {
	position: relative;
	padding-left: 2.3em;
	font-size: 1.05em;
	line-height: 1.7;
	cursor: pointer;
	display: flex;
}

/* checkbox aspect */
.review-container [type="radio"]:not(:checked) + label:before,
.review-container [type="radio"]:checked + label:before {
	content: "";
	position: absolute;
	left: 30%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	width: 30px;
	height: 30px;
	box-shadow: 4px 4px 10px #e9e9e9;
	background: #eee;
	border-radius: 0.2em;
	/* box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 0 rgba(203, 34, 237, 0.2); */
	-webkit-transition: all 0.275s;
	transition: all 0.275s;
	border-radius: 100%;
}
.review-container [type="radio"]:not(:checked) + label:after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 16px;
	height: 16px;
	background: #eee;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	border-radius: 100%;
	box-shadow: 10px 13px 10px -8px rgba(224, 224, 224, 0.75) inset;
	-webkit-box-shadow: 10px 13px 10px -8px rgba(224, 224, 224, 0.75) inset;
	-moz-box-shadow: 10px 13px 10px -8px rgba(224, 224, 224, 0.75) inset;
}
.review-container .thank-you-text {
	font-family: "Ulagadi Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 27px;
	color: #285d69;
	width: 35vw;
	margin-top: 10vh;
}
/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
.review-container [type="radio"]:checked + label:after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	width: 16px;
	height: 16px;
	background: linear-gradient(
		136.79deg,
		#C6EFF5 15.44%,
		#93b1ff 63.41%,
		#0056d8 123.2%
	);
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	border-radius: 100%;
}
/* checked mark aspect changes */
.review-container [type="radio"]:not(:checked) + label:after {
	opacity: 1;
	/* -webkit-transform: scale(0) rotate(45deg);
	transform: scale(0) rotate(45deg); */
}

.review-container [type="radio"]:checked + label:after {
	opacity: 1;
	-webkit-transform: scale(1) rotate(0) translate(-50%, -50%);
	transform: scale(1) rotate(0) translate(-50%, -50%);
}

/* Disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
.review-container [type="radio"]:disabled:checked + label:before {
	box-shadow: none;
	border-color: #bbb;
	background-color: #e9e9e9;
}

.review-container [type="radio"]:disabled:checked + label:after {
	color: #777;
}

.review-container [type="radio"]:disabled + label {
	color: #aaa;
}

.box-survey {
	background: rgba(255, 255, 255, 0.8);
	box-shadow: 8px 8px 20px rgb(192 192 192 / 25%);
	backdrop-filter: blur(20px);
	border-radius: 15px;
	padding: 20px 50px;
	margin: 0px 0px 46px 0px;
}
.css-1xf95kq-MuiButtonBase-root-MuiButton-root:after {
    position: absolute;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    background: linear-gradient(to right, #C6EFF5 6.33%, #93B1FF 74.55%, #0056D8 108.54%);
    content: "";
    z-index: -1;
    border-radius: 50px;
}

@media screen and (max-width: 900px) {
	.box-survey {
		padding: 20px 20px;
	}
	.que-box {
		margin-top: 10%;
	}
	.col-100 {
		margin-top: 3%;
	}
}

.box-survey .subtitle-1 {
	font-family: "Ulagadi Sans";
	font-style: normal;
	font-weight: 200;
	font-size: 20px;
	line-height: 27px;

	color: #285d69;
}

/* MOVIL TABLE */
.evaluation-movil {
	display: none;
}

@media screen and (max-width: 900px) {
	.review-container {
	}
	.evaluation-pc {
		display: none;
	}

	.evaluation-movil {
		display: block;
		font-size: 12px;
		margin-top: 50px;
	}
	.evaluation-movil table {
		width: 100%;
	}

	.review-container .btn {
		width: 100%;
	}
}
