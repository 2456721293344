.questionnarie-container {
	display: flex;
	flex-wrap: wrap;

	align-items: center;
	padding: 50px;
	height: 100%;
}
.questionnarie-container .questionnarie-box {
	width: 100%;
}
.questionnarie-container .header {
	width: 100%;
}
.questionnarie-container .header .btn-back {
	background: #646464;
	color: #fff;
}
.questionnarie-container .titulo-ques {
	width: 100%;
	margin-top: 50px;
}
.questionnarie-container .questions-container {
	width: 100%;
	margin-top: 20px;
	margin-bottom: 40px;
}
.questionnarie-container .questions-container.fadeIn {
	animation-name: fadeIn;
	animation-duration: 0.5s;
}
.questionnarie-container .questions-container.fadeOut {
	opacity: 0;
	/* animation-name: fadeOut;
    animation-duration: 0.5s;
    animation-fill-mode: forwards; */
}

.questionnarie-container .options-box {
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
	margin-top: 10px;
}
.questionnarie-container .input-question {
	padding: 10px 15px;
	width: 30%;
	border-radius: 10px;
	border: none;
	background: #ddd;
	margin-top: 10px;
}
.questionnarie-container .btn-opcion {
	background: #ddd;
}
.questionnarie-container .radio {
	display: none;
}
.questionnarie-container .label-radio {
	background-color: #ddd;
	padding: 15px 40px;
	border-radius: 10px;
	font-size: 14px;
	transition: 0.5s;
}
.questionnarie-container .radio[type="radio"]:checked + .label-radio {
	background-color: #333;
	color: #fff;
	transition: 0.5s;
}
.questionnarie-container .btn-prev {
	background: #646464;
	color: #fff;
}
.questionnarie-container .btn-next {
	text-decoration: none;
	background: rgb(46, 129, 253);
	color: #fff;
}
.questionnarie-container .btn {
	border: none;
	padding: 10px 40px;
	border-radius: 10px;
	cursor: pointer;
	transition: 0.5s;
}
.questionnarie-container .btn:disabled {
	background-color: #ddd;
	color: #646464;
	cursor: no-drop;
	transition: 0.5s;
}

@keyframes fadeIn {
	from {
		margin-left: 100px;
		opacity: 0;
	}
	to {
		margin-left: 0px;
		opacity: 1;
	}
}

@keyframes fadeOut {
	from {
		transform: scale(1);
	}
	to {
		transform: scale(0);
	}
}

@media screen and (max-width: 900px) {
	.questionnarie-container {
		padding: 30px;
	}
	.input-question {
		width: 80% !important;
	}
}
