.req-container .lds-ring {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.req-container .lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 40px;
	height: 40px;
	margin: 8px;
	border: 4px solid rgb(46, 129, 253);
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: rgb(46, 129, 253) transparent transparent transparent;
}
.req-container .lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.req-container .lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.req-container .lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}

.req-container .loading-box {
	flex-direction: column;
	height: 200px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.req-container .loading-box p {
	font-size: 12px;
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
