.billing-container {
	/* padding: 30px 0px;
	margin-top: 20px;
	width: calc(100% + 3%);
	padding-right: 3%;
	margin-bottom: 30px; */
}
.billing-container form {
	width: 35%;
}
/*.billing-container input{
    padding: 9px;
    border: 1px solid;
    width: 100%;
    margin-bottom: 10px;
}
 .billing-container table{
    width: 100%;
    border-collapse: collapse;
}
.billing-container th{
    text-align: flex-start;
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-left: 1px solid;
    padding: 10px;
    border-collapse: collapse;
}
.billing-container td{
    text-align: flex-start;
    border-right: 1px solid;
    border-left: 1px solid;
    padding: 10px;
    border-collapse: collapse;
} */
.billing-container .table-header-text {
	font-family: "Ulagadi Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	/* identical to box height */

	text-align: center;

	color: #94a3ca;
	border-bottom: 0px;
	border-right: 1px solid #94a3ca;
	border-top: 1px solid #94a3ca;
}
.billing-container .table-header-text:first-of-type {
	border-left: 1px solid #94a3ca;
}
.billing-container .table-header-inner-text {
	font-family: "Ulagadi Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 22px;
	/* identical to box height */

	color: #94a3ca;
	border-bottom: 0px;
	background: #f8f8f8;
}
.billing-container .table-cell-text {
	font-family: "Ulagadi Sans";
	font-style: normal;
	font-weight: 200;
	font-size: 16px;
	line-height: 22px;
	/* identical to box height */

	color: #000000;
	border-bottom: 0px;
	border-right: 1px solid #94a3ca;
}
.billing-container .table-cell-text:first-of-type {

	border-left: 1px solid #94a3ca;
}
.billing-container .table-cell-inner-text {
	font-family: "Ulagadi Sans";
	font-style: normal;
	font-weight: 200;
	font-size: 12px;
	line-height: 22px;
	/* identical to box height */

	color: #000000;
	border-bottom: 0px;
}
.billing-container table img {
	max-width: 30px;
	display: block;
	margin: auto;
}

@media screen and (max-width: 900px) {
	.billing-container form {
		width: 100%;
	}
	.billing-container input {
		width: 92%;
	}
	.billing-container table {
		font-size: 12px;
	}
	.billing-container th {
		padding-right: 60px;
	}
	.billing-container td {
		padding: 3px 10px;
	}
	.table-container {
		max-width: 90vw;
		overflow: auto;
	}
	.my-profile-container .inputs-container {
		width: 100%;
	}
}
