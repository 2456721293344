@media screen and (max-width: 900px) {
	.item {
		max-width: 100%;
	}
}

.signin-container {
	display: grid;
	grid-template-rows: 0.1fr 1fr 0.1fr;
	width: 100%;
	height: 100%;
	background-color: whitesmoke;
	margin-top: 80px;
}

.signin-form {
	place-self: center;
	display: grid;
	align-content: center;
	justify-items: center;
	margin: 0 auto;
	height: fit-content;
	width: 80%;
}

.pSignup {
	color: #bf1650;
}

/* p::before {
  display: inline;
  content: "⚠ ";
} */

.containerGoogleLogo {
	display: flex;
	justify-content: center;
}

.containerGoogleLogo img {
	width: 50%;
}

.divider-container {
	width: 100%;
}

.signin-form-title {
	font-size: 4rem;
	padding-bottom: 1rem;
	text-align: center;
}

.container-already-logged {
	display: flex;
	flex-wrap: wrap;
	padding: 50px;
	justify-content: center;
	align-items: center;
}

.container-already-logged p {
	width: 100%;
	text-align: center;
}
.container-already-logged button {
	background: #1976d2;
	color: #fff;
	border-radius: 10px;
	border: none;
	padding: 10px 40px;
	margin-top: 20px;
	font-size: 14px;
	margin-top: 10px;
	display: block;
	width: fit-content;
	text-decoration: none;
}
.inputItem{
	background-color: transparent;
}

@media screen and (max-width: 900px) {
	/* .MuiInputLabel-formControl {
    font-size: 0.9rem !important;
  } */
}
/* @media (min-width: 1536px) {
	.css-1v6h0jl-MuiPaper-root {
	  width: 35% !important;
	}
  } */

/* Dialogs */

.agreementsContainer {
	padding: 1% 5% 1% 5%;
}
