.meet-team-container {
}
.meet-team-container .col-100 {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}
.meet-team-container .team-item-box {
}



.team-item-info {
}
.meet-team-container .team-item-info p {
	margin: 0px !important;
	width: 100%;
}
.meet-team-container .team-item-info ul {
	margin: 5px;
	/* padding-left: 15px; */
	font-size: 13px;
	width: 100%;
}
.meet-team-container .smll-link {
	font-size: 14px;
	padding-bottom: 2%;
	text-decoration: underline;
	width: 100%;
}
.meet-team-container .btn-share-credentials {
	border: none;
	padding: 10px 0px;
	font-size: 14px;
	display: block;
	width: 250px;
	background-color: transparent;
}
.meet-team-container .btn-share-credentials.disable {
	background-color: transparent;
}
.btn-share-credentials:hover {
	cursor: pointer;
}

.modal-credentials {
	display: none;
}
.modal-credentials.fade-out {
	display: none;
}
.modal-credentials.fade-in {
	display: block;
}
.modal-credentials-background {
	position: fixed;
	/* background: #3333338f; */
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
}
.modal-credentials-container {
	backdrop-filter: blur(5px);
	background: rgba(255, 255, 255, 0.8);
	position: fixed;
	top: 50%;
	z-index: 1;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 60%;
	height: auto;
	padding: 50px;
	box-sizing: border-box;
	border-radius: 20px;
	overflow-y: auto;
	overflow-x: hidden;
	z-index: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.modal-credentials-container .btn-close {
	position: absolute;
	left: 0;
	top: 0;
	height: 60px;
	width: 60px;
	border: none;
	background: transparent;
	color: #333;
	cursor: pointer;
	font-weight: 100;
	font-size: 20px;
}

.form-encrypted-credentials {
	background-color: #ffffff;
	border-radius: 15px;
	border: 1px solid #000000;
	display: flex;
	flex-direction: column;
	padding: 5% 12% 5% 12%;
}

.modal-credentials-container h2 {
	text-align: center;
}

.modal-credentials-container .col-50 {
	width: 50%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}
.modal-credentials-container form label {
	/* width: 100%;
	text-align: center; */
	display: flex;
	justify-content: center;
	align-items: center;
}
.modal-credentials-container .col-100 {
	width: 100%;
	display: flex;
	justify-content: center;
}

.container-btn-encrypt {
	width: 100%;
	display: flex;
	justify-content: center;
	padding-top: 5%;
}

.modal-credentials-container .btn {
	color: #fff;
	border-radius: 11px;
	border: none;
	padding: 10px 30px;
	font-size: 13px;
	margin-top: 15px;
	cursor: pointer;
}
.modal-credentials-container .btn.hide {
	display: none;
}

.input-form-encrypt {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding-top: 5%;
	gap: 5%;
}

.modal-credentials-container form input {
	width: 60%;
	padding: 10px 20px;
	box-sizing: border-box;
	font-family: Arial, Helvetica, sans-serif;
	background: #fff;
	border-radius: 9px;
	border: 1px solid;
	display: flex;
}
.modal-credentials-container .encrypted-credentials-container.hide {
	display: none;
}
.modal-credentials-container .encrypted-credentials-box {
	padding: 20px 50px;
	border-radius: 20px;
	margin: 20px 0px;
}
.modal-credentials-container .encrypted-credentials-box p {
	text-align: justify;
}
.modal-credentials-container .computers-box {
	padding-left: 30px;
	margin: 20px 0px;
}
.modal-credentials-container .computer {
	display: flex;
	align-items: center;
	gap: 20px;
}
.modal-credentials-container .computer p {
	font-size: 14px;
}
.modal-credentials-container .img-computer {
	max-width: 60px;
}
 .info-smll {
	font-size: 14px;
	text-align: justify;
}
.modal-credentials-container .btn-share {
	background: rgb(46, 129, 253);
}

@keyframes fadeInModal {
	from {
		margin-left: 100px;
		opacity: 0;
	}
	to {
		margin-left: 0px;
		opacity: 1;
	}
}

@keyframes fadeOutModal {
	from {
		margin-left: 0px;
		opacity: 1;
	}
	to {
		margin-left: 100px;
		opacity: 0;
	}
}

@media screen and (max-width: 900px) {
	.meet-team-container {
	}
	.meet-team-container h2 {
		font-size: 18px;
	}
	.team-item-info {
		width: 70%;
	}
	.meet-team-container .team-item-info ul {
		font-size: 12px;
	}
	.meet-team-container .team-item-info p {
		font-size: 12px;
	}
	.meet-team-container .name b {
		font-size: 14px;
	}
	.meet-team-container .team-item-info {
		margin-left: 15px;
	}
	.meet-team-container .btn-share-credentials {
		width: inherit;
		padding: 10px 0px;
	}

	.modal-credentials-container {
		width: 90%;
		padding: 30px;
	}
	.modal-credentials-container .col-50 {
		width: 100%;
	}
	.modal-credentials-container .encrypted-credentials-box {
		padding: 10px;
	}
	.modal-credentials-container .encrypted-credentials-box p {
		font-size: 14px;
	}
}
