.work-inprogress-container {
	/* padding: 50px 21.25%; */
}
.work-inprogress-container h3 {
	font-family: "Ulagadi Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 30px;
	line-height: 41px;
	color: #112031;
}
.work-inprogress-container .subtitle-1 {
	font-family: "Ulagadi Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 20px;
	color: #285d69;
	line-height: 20px;
	margin-top: 23px;

	display: flex;
	align-items: center;
	gap: 10px;
}
.work-inprogress-container .subtitle-2 {
	font-family: "Ulagadi Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 27px;
	color: #285d69;
	margin-bottom: 10px;
}
.work-inprogress-container .col-100 {
	display: flex;
}
.work-inprogress-container .btn {
	font-family: "Ulagadi Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	text-align: center;
	color: #ffffff;
	padding: 10px 40px;
	border: none;
	width: 250px;
	border: none;
	border-radius: 11px;

	/* background-color: #ddd;
	color: #fff;
	border-radius: 10px;
	font-size: 14px;
	display: block;
	text-decoration: none;
	margin-right: 10px; */
}
.work-inprogress-container .platform-name {
	font-family: "Ulagadi Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	color: #285d69;
}
.work-inprogress-container .task-container {
	background: rgba(255, 255, 255, 0.8);
	box-shadow: 8px 8px 20px rgb(192 192 192 / 25%);
	backdrop-filter: blur(20px);
	border-radius: 15px;
	border: 0px;
	padding: 40px 50px;
	width: 100%;
}
.work-inprogress-container .task-container .completed-text {
	position: absolute;
	top: -3.5vh;
	font-size: 22px;
	z-index: 100;
	font-weight: 700;
	text-transform: uppercase;
}
.work-inprogress-container .image-team-member-task {
	position: absolute;
	bottom: 15px;
	height: fit-content;
	right: 50px;
	display: flex;
	align-items: center;
	margin-bottom: 30px;
}
.spanVLE{
display: inline-block;
}
.work-inprogress-container img.team-member-image {
	border: none;
	width: 30px;
	height: 30px;
	object-fit: cover;
	border-radius: 100%;
}
.work-inprogress-container .image-team-member-task:hover p {
	display: block;
}
.work-inprogress-container .image-team-member-task p {
	position: absolute;
	background: #fff;
	padding: 7px;
	width: max-content;
	border-radius: 15px;
	top: -40px;
	left: 30px;
	box-shadow: 8px 8px 20px rgb(192 192 192 / 25%);
	font-family: "Ulagadi Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	color: #94a3ca;
	display: none;
}
.work-inprogress-container .col-50 {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	width: 100%;
	gap: 10px;
	align-content: center;
}
.work-inprogress-container .col-30 {
	display: flex;
	flex-wrap: wrap;
	width: 30%;
}
.work-inprogress-container .col-70 {
	display: flex;
	flex-wrap: wrap;
	width: 70%;
}
.work-inprogress-container .col-30 p {
	margin: 0px;
}
.work-inprogress-container .col-50 p {
	margin: 0px;
	font-family: "Ulagadi Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 20px;
	color: #285d69;
}
.work-inprogress-container .col-70 p {
	margin: 0px;
}
.work-inprogress-container li {
	list-style: none;
}
.work-inprogress-container .stepper-work {
	padding: 0px;
	display: flex;
	justify-content: space-around;
	width: 100%;
	/* margin-right: 10vw; */
}
.work-inprogress-container .stepper-work-mobile {
	padding: 0px;
	display: flex;
	justify-content: space-around;
	width: 100%;
	/* margin-right: 10vw; */
}
.work-inprogress-container .stepper-work-mobile li {
	position: relative;
	width: 25%;
}
.work-inprogress-container .stepper-work li {
	position: relative;
	width: 25%;
}
.work-inprogress-container .stepper-work-mobile li p {
	font-family: "Ulagadi Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 19px;
	color: #5f5f5f;
	margin: 0px;
	width: 100%;
	text-align: center;
}
.work-inprogress-container .stepper-work li p {
	font-family: "Ulagadi Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	color: #5f5f5f;
	margin: 0px;
	width: 100%;
	text-align: center;
}
.work-inprogress-container .stepper-work-mobile li.active p {
	font-weight: 600;
}
.work-inprogress-container .stepper-work li.active p {
	font-weight: 600;
}
.work-inprogress-container .active .circle {
	height: 27px;
	width: 27px;
	background: linear-gradient(
		141.2deg,
		#C6EFF5 24.23%,
		#93b1ff 61.32%,
		#0056d8 104.45%
	);
	border-radius: 100%;
	margin: auto;
	border: 5px solid #eee;
}
.work-inprogress-container .approve .circle {
	background: #c0c0c0;
	background: #c0c0c0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	color: #fff;
}
.work-inprogress-container .approve .circle:after {
	content: "✔";
}
.work-inprogress-container .circle {
	background: linear-gradient(
		296.57deg,
		#eeeeee 30.81%,
		#d9d9d9 95.05%,
		#bdbdbd 116.49%
	);
	height: 27px;
	width: 27px;
	border-radius: 100%;
	margin: auto;
	border: 5px solid #eee;
}
.work-inprogress-container .line-steper-right {
	width: 50%;
	height: 5px;
	background: #eee;
	position: absolute;
	left: calc(50% + 13px);
	top: 11.5px;
}
.work-inprogress-container .line-steper-left {
	width: 50%;
	height: 5px;
	background: #eee;
	position: absolute;
	right: calc(50% + 13px);
	top: 11.5px;
}

.work-inprogress-container .progress-video-container {
	display: flex;
	width: 100%;
	margin-top: 50px;
	flex-wrap: wrap;
	gap: 20px;
}
.work-inprogress-container .progress-video-container iframe {
	height: 220px;
	margin-top: 10px;
}
.work-inprogress-container .progress-video-container .approve-btn-container {
	justify-content: space-between;
	align-content: center;
	gap: 10px;
	width: 100%;
	flex-direction: column;
}
.work-inprogress-container .creds-container-progress {
	align-items: center;
	margin-top: 10px;
}
.work-inprogress-container .creds-container-progress p {
	font-family: "Ulagadi Sans";
	font-style: normal;
	font-weight: 200;
	font-size: 15px;
	line-height: 20px;
	font-size: 12px;
	display: flex;
	align-items: center;
	margin-left: 30px;
	color: #94a3ca;
}
.work-inprogress-container .reject {
	width: fit-content;
	font-family: "Ulagadi Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	padding: 0px;
	line-height: 22px;
	text-align: center;
	text-decoration-line: underline;
	background: linear-gradient(
		93.93deg,
		#C6EFF5 -1.35%,
		#93b1ff 65.75%,
		#0056d8 121.35%
	);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	display: block;
	margin: auto;
	border-radius: 0px;
	border-bottom: 1px solid #93b1ff;
}
.work-inprogress-container .image-video {
	max-width: 100%;
	width: 256px;
	height: 144px;
	border-radius: 10px;
	margin: 0 auto;
	object-fit: contain;
	box-shadow: 8px 8px 20px rgb(192 192 192 / 25%);
	background: linear-gradient(
		0deg, 
		#F1F7FF 0%,
		#ffffff 100%
	);
}
.modalDialogTitle{
	margin-bottom: 20px;
}


.work-inprogress-container .title-task {
	font-family: "Ulagadi Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 27px;
	color: #285d69;
	/* margin-bottom: 70px; */
}

.css-lehbgi-MuiPaper-root {
	min-width: 20%;
}
.step-container {
	max-width: 50%;
}

.modal-details-container {
	background-color: #fff;
	width: 500px;
	padding: 20px;
	box-shadow: -1px 0px 41px 0px rgba(0, 0, 0, 0.5);
	-webkit-box-shadow: -1px 0px 41px 0px rgba(0, 0, 0, 0.5);
	-moz-box-shadow: -1px 0px 41px 0px rgba(0, 0, 0, 0.5);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

canvas {
	z-index: 9999 !important;
}

.work-in-progress-skeleton-container {
	height: 240px;
	border-radius: 15px;
	width: 100%;
	margin-top: 40px;
	background-image: -webkit-linear-gradient(
		left,
		#ececec 0px,
		#eee 40px,
		#ececec 80px
	);
	background-image: -o-linear-gradient(
		left,
		#ececec 0px,
		#eee 40px,
		#ececec 80px
	);
	background-image: linear-gradient(
		90deg,
		#ececec 0px,
		#eee 40px,
		#ececec 80px
	);
	background-size: 250px;
	-webkit-animation: shine-loading-image 2s infinite ease-out;
	animation: shine-loading-image 2s infinite ease-out;
}

@-webkit-keyframes shine-loading-image {
	0% {
		background-position: -32px;
	}
	40%,
	100% {
		background-position: 208px;
	}
}

@keyframes shine-loading-image {
	0% {
		background-position: -32px;
	}
	40%,
	100% {
		background-position: 208px;
	}
}

@media screen and (max-width: 900px) {
	.work-inprogress-container {
		/* padding: 20px; */
	}
	.work-inprogress-container .creds-container-progress {
		padding: 0px;
		font-size: 12px;
	}
	.work-inprogress-container .btn {
		width: 100px;
		padding: 7px 0px;
	}
	.work-inprogress-container .creds-container-progress p {
		width: 160px;
		margin: 0px;
	}
	.work-inprogress-container .task-container {
		padding: 15px;
		width: 100%;
		font-size: 12px;
	}
	.work-inprogress-container .col-70 {
		width: 100%;
	}
	.work-inprogress-container .col-30 {
		width: fit-content;
		margin-top: 10px;
	}
	.work-inprogress-container .circle {
		width: 20px;
		height: 20px;
	}
	.work-inprogress-container .active .circle {
		width: 20px;
		height: 20px;
		border: 2px solid #0839cf;
	}
	.work-inprogress-container .line-steper-left {
		height: 2px;
		top: 10px;
		right: calc(50% + 10px);
	}
	.work-inprogress-container .line-steper-right {
		height: 2px;
		top: 10px;
		left: calc(50% + 10px);
	}
	.work-inprogress-container .stepper-work li p {
		font-size: 10px;
	}
	.work-inprogress-container .image-team-member-task {
		position: unset;
		margin-bottom: 15px;
	    margin-left: 15px;

	}
	.work-inprogress-container .task-container img.team-member-image {
		width: 30px;
		height: 30px;
		border: 2px solid;
	}
	.work-inprogress-container .progress-video-container {
		flex-wrap: wrap;
	}
	.work-inprogress-container .col-50 {
		display: flex;
		width: 100%;
		gap: 10px;
	}
	.work-inprogress-container .approval {
		font-size: 14px;
		width: 200px;
	}
	.spanVLE{
		display: none;
		}
}
